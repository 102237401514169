import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import TemplatesList from '@/components/templates/TemplatesList.vue';
let TemplatesIndexPage = class TemplatesIndexPage extends Vue {
    constructor() {
        super(...arguments);
        this.pagination = {
            page: 1,
            total: 0,
            limit: 25,
        };
    }
    async openTemplate(template) {
        await this.$router.push({
            name: 'org.templates2.manage',
            params: {
                templateId: template.id.toString(),
            },
        });
    }
    async createTemplate() {
        await this.$router.push({
            name: 'org.templates2.manage',
            params: {
                templateId: 'new',
            },
        });
    }
};
TemplatesIndexPage = __decorate([
    Component({
        components: {
            TemplatesList,
        },
    })
], TemplatesIndexPage);
export default TemplatesIndexPage;
