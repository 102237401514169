import { __decorate } from "tslib";
import { Vue, Component, Emit, Watch, Inject, Prop } from 'vue-property-decorator';
import TemplatesApi from '@/services/api/templates.api';
let TemplatesList = class TemplatesList extends Vue {
    constructor() {
        super(...arguments);
        this.tableFields = [
            {
                key: 'index',
                label: '#',
                thStyle: { width: '4%' },
            },
            {
                key: 'name',
                label: 'Name',
                thStyle: { width: '20%' },
            },
            {
                key: 'description',
                label: 'Description',
                thStyle: {},
            },
            {
                key: 'actions',
                label: '',
                thStyle: { width: '70px' },
            },
        ];
        this.loading = false;
        this.searchText = '';
        this.templates = [];
        this.pagination = {
            page: 1,
            total: 0,
            limit: 25,
        };
    }
    get localOffset() {
        return this.pagination.limit * (this.pagination.page - 1);
    }
    onPaginationPerPageChanger() {
        this.onPageChanged();
    }
    onTemplateSelected(row) {
        return row;
    }
    onPageChanged() {
        this.$nextTick(() => this.fetchTemplates());
    }
    mounted() {
        this.fetchTemplates();
    }
    // eslint-disable-next-line class-methods-use-this
    async fetchTemplates() {
        this.loading = true;
        this.templates = [];
        try {
            const result = await TemplatesApi.find({
                page: this.pagination.page,
                organizationId: +this.organizationId,
                limit: this.pagination.limit,
                search: this.searchText,
            });
            this.pagination.total = result.total;
            this.templates = result.templates;
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        this.loading = false;
    }
    async deleteTemplate(id) {
        try {
            if (await this.$confirm()) {
                const { message } = await TemplatesApi.delete(id);
                this.$toast.success(message);
                await this.fetchTemplates();
            }
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
};
__decorate([
    Inject()
], TemplatesList.prototype, "organizationId", void 0);
__decorate([
    Prop({
        default: true,
    })
], TemplatesList.prototype, "deletable", void 0);
__decorate([
    Watch('pagination.page', { deep: true }),
    Watch('searchText')
], TemplatesList.prototype, "onPaginationPerPageChanger", null);
__decorate([
    Emit()
], TemplatesList.prototype, "onTemplateSelected", null);
TemplatesList = __decorate([
    Component({})
], TemplatesList);
export default TemplatesList;
